<template>
  <div class="post-comments">
    <div class="container">
      <div class="row border-top">
        <div class="col-12 alert alert-danger" v-if="error != null">
            {{ error }}
          </div>
        <div class="col-12 comment d-flex" v-for="comment in comments" :key="comment.id">
          <div class="col-2">
              <p class="user">{{ comment.name }}</p>
          </div>
          <div class="col-10 comment-body">
            <p class="comment-text">
              {{ comment.txt }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HttpClient from '@/services/http.service';
export default {
  name:'PostComments',
  props:{
    postId:Number
  },
  data(){
    return{
      error:null,
      comments:null
    }
  },
  methods:{
     LoadComments: async function(){
        try {
          let {status,data} = await HttpClient.get(`/${this.$i18n.locale}/comment/index`,{params:{pid:this.postId}});
          if (status == 200) {
            this.comments = data;
          }
        } catch (e) {
          this.error = e;
        }
      }
  },
  async created(){
    await this.LoadComments();
  },
}
</script>

<style lang="css" scoped>
.comment{
  border-image-source: linear-gradient(90deg, #0c7da6 0%, #00ffff 100%);
  border-image-slice: 1;
  border-bottom: 2px solid;
}
.border-top{
  border-image-source: linear-gradient(90deg, #0c7da6 0%, #00ffff 100%);
  border-image-slice: 1;
  border-top: 4px solid;
}
.user{
  color: #fff;
  font-size: 18px;
  font-weight: bolder;
}
.comment-body{
  background-color: #ffffff0a;
  color: #fff;
  font-size: 18px;
  line-height: 3rem;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
