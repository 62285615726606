<template>
  <div class="t-logo">
    <img src="/img/1.png" class="t-logo-img" alt="آذر پژوهش">
  </div>
</template>

<script>
export default {
  name:'t-logo',
}
</script>

<style lang="css">
.t-logo{
  width: 100%;
  display: flex;
  flex:1;
  align-items: center;
  justify-content: flex-start;
}
.t-logo-img{
  width:5%;
  margin-right:5%;
}
</style>
