<template lang="html">
    <div class="posts">
      <t-header class="my-4"></t-header>
      <t-logo class="my-4"></t-logo>
      <single-post :post="post"></single-post>
    </div>
</template>

<script>
import THeader from '../components/THeader';
import TLogo from '../components/TLogo';
import SinglePost from '../components/SinglePost';
import HttpClinet from '../services/http.service';
export default {
  name:'TCategory',
  components: {
    't-header' : THeader,
    't-logo' : TLogo,
    SinglePost
  },
  data(){
    return {
      post:null,
      postTitle:this.$route.params.tocategory,
    };
  },
  methods: {
    async postGenrator() {
      try {
          let {status,data} = await HttpClinet.get(`/${this.$i18n.locale}/tomorrow/view`,{params:{title:this.postTitle}});
          if (status == 200) {
            this.post = data;
          }
      } catch (e) {
        console.log(e);
      }
    }
  },
  created() {
    //do something after creating vue instance
    this.postGenrator();
  }
}
</script>

<style lang="css" scoped>
</style>
