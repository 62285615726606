<template>
  <div class="SinglePost">
    <div class="row justify-content-center align-items-center">
      <div class="col-4">
        <h1 class="t-title">{{ post.data.name }} </h1>
      </div>
    </div>
    <post-view class="mb-4 pb-4" :post="post"></post-view>
    <post-comments class="mt-4 pt-4" :postId="post.id"></post-comments>
    <add-comment :postId="post.id"></add-comment>
    <div class="empty">
    </div>
    <Footer />
  </div>
</template>

<script>
import PostView from './PostView'
import PostComments from './PostComments'
import AddComment from './AddComment'
import Footer from './Footer';
export default {
  name:'SinglePost',
  components: {
    PostView,
    PostComments,
    AddComment,
    Footer
  },
  props:{
    post:Object
  },
}
</script>

<style lang="css" scoped>
.t-title{
  color: #fff;
  font-size: 30px;
  text-align: center;
  font-weight: bolder;
  border-bottom: 2px solid;
  border-image-source: linear-gradient(90deg, #0c7da6 0%, #00ffff 100%);
  border-image-slice: 1;
  padding-bottom: 7px;
}
</style>
