import { render, staticRenderFns } from "./THeader.vue?vue&type=template&id=7c56c57c&scoped=true&"
import script from "./THeader.vue?vue&type=script&lang=js&"
export * from "./THeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c56c57c",
  null
  
)

export default component.exports