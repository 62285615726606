<template>
  <div class="t-header">
    <v-menu></v-menu>
  </div>
</template>

<script>
import Menu from './Menu';
export default {
  name:'THeader',
  components: {
    'v-menu':Menu,
  }
}
</script>

<style lang="css" scoped>
</style>
